/* Small devices (tablets, 768px and up = SM-MIN) */
/* Medium devices (desktops, 992px and up = MD-MIN) */
/* Large devices (large desktops, 1200px and up = LG-MIN) */
@light-grey: rgba(240,240,240,1);
@page-color: #349c90;
* {
    //outline: 1px solid red;
}
*,
*:after,
*:before {
    box-sizing: border-box;
}
.box-shadow-bottom {
    box-shadow: 0 20px 40px 0 rgba(50, 50, 50, 0.75);
}
.box-shadow-top {
    box-shadow: 0 -20px 40px 0 rgba(50, 50, 50, 0.75);
}
.fixed {
    position: fixed;
}
.h1,
.h2,
.h3,
.h4,
h1,
h2,
h3,
h4 {
    margin-top: 0;
}
a {
    color: white;
}
a:hover {
    color: @light-grey;
}
body {
    background-color: @page-color;
    font-family: 'Raleway', sans-serif;
    * {
        font-family: 'Raleway', sans-serif;
    }
}
section {
    background-color: white;
}
a {
    color: white;
}
.container {
    position: relative;
    z-index: 1;
    margin-top: 50px;
    margin-bottom: 50px;
}
.container-fluid {
    position: relative;
    z-index: 1;
}
.bg {
    top: 0;
    width: 100%;
}
.carousel {
    .item {
        img {
            max-width: 100%;
            height: auto;
            width: 100%;
        }
    }
}
.slider-controls {
    position: relative;
    z-index: 10;
    top: -250px;
    display:none;
    .right {
        right: 20px;
    }
}
.flat-button {
    display: inline-block;
    padding: 10px 20px;
    transition: 0.2s all;
    color: white;
    border: 2px solid white;
    a {
        color: white;
    }
}
.slider-top-content {
    position: absolute;
    top: -200px;
    left: 30px;
}
.carousel-static__content {
    //position: relative;
    //top:-400px;
    h2 {
        font-size: 30px;
        font-weight: bold;
    }
    p {
        font-size: 22px;
    }
    h2,
    p {
        position: relative;
        z-index: 1;
        color: white;
    }
    .carousel-static__content--image {
        position: absolute;
        //top:-500px;
        z-index: -1;
        top: -100px;
        height: 300px;
    }
}
.flat-button:hover {
    color: @page-color;
    background-color: white;
}
.logo-wof {
    width: 150px;
}
.logo {
    width: 250px;
    margin-top: 50px;
}
// Hero
.hero {
    position: relative;
    z-index: 2;
    //top: -370px;
    overflow: hidden;
    width: 100%;
    height: 550px;
    top:-80px;
    transform: skew(0deg,5deg);
    background-color: @page-color;
    margin-bottom:200px;
    .box-shadow-bottom;
    .hero-bg {
        max-width: 100%;
        height: 110%;
        margin-top:100px;
        transform: skew(0deg,-5deg);
        background-image: url("../../assets/img/bg/pattern.png");
    }
    .container {
        position: relative;
        .row {
            margin: 25px auto;
        }
        .glyphicon {
            padding: 30px 0;
            color: white;
            font-size: 2em;
        }
    }
    .chevron-down {
        width: 40px;
        margin: 20px auto;
    }
}
.carousel-control {
    bottom: 100px;
}
.controls {
    position: absolute;
    z-index: 5;
    top: 50%;
    display: inline-block;
    width: 20px;
    margin-top: -10px;
}
// Hero End
//Page
.page-cred {
    .box-shadow-top;
}
//Food
.container-food {
    color: white;
    background-color: @page-color;
    background-image: url("../../assets/img/bg/pattern.png");
    .more {
        margin-top: 30px;
    }
}
.separator {
    background-color: white;
    .chevron-down {
        width: 40px;
        margin: 20px auto;
    }
}
.menuCheck{
    font-weight: bold;
    padding:5px 0;
}
tr{
    margin-bottom:5px;
}
.tu{
        font-weight: bold;
        border-right:1px solid white;
        padding-right:5px;
}
.nl{
    padding-left:5px;
}
//Contact
.contact-info {
    padding-left: 0;
    li {
        list-style: none;
    }
}
//Contact End
.list-table {
    width: 100%;
    margin: 0 0 10px;
    padding: 0;
    li {
        margin: 0;
        list-style: none;
    }
}
.map {
    width: 100%;
}
.maps {
    margin: 0 20px;
}
footer {
    display: block;
    background-color: #333333;
    .logo-wof {
        margin: 20px auto;
    }
    .footer-logo {
        display: block;
        width: 150px;
    }
    .btn {
        display: block;
    }
    .social-facebook {
        width: 30px;
    }
    .copyright{
        color: white;
    }
}
@media (max-width: 767px) {
    .bg {
        //position: relative;
        //top: 450px;
    }
    .btn {
        width: 100%;
        margin: 20px auto;
    }
}
/* Small devices (tablets, 768px and up) */
@media (min-width: 768px) {
    .h1,
    .h2,
    .h3,
    .h4,
    h1,
    h2,
    h3,
    h4 {
        margin-top: 20px;
    }
    .hero {
        height: 550px;
        top: -125px;
        margin-bottom: 200px;
    }
    .map {
        height: 400px;
    }

    .slider-controls {
        display: block;
        top: -60px;
        left: -10px;
        .right {
            right: -15px;
        }
    }
    .controls {
        top: 50%;
        width: 30px;
    }
    .carousel-indicators {
        display: none;
    }
    .slider-top-content {
        //top: -335px;
        padding-right: 50px;
        padding-left: 50px;
    }
    .slider-controls {}
    .controls {
        width: 20px;
    }
    .carousel-static__content {
        //position: relative;
        //top:-400px;
        h2 {
            font-size: 48px;
            font-weight: bold;
        }
        p {
            font-size: 36px;
        }
        h2,
        p {
            position: relative;
            z-index: 1;
            color: white;
        }
        .carousel-static__content--image {
            position: absolute;
            //top:-500px;
            z-index: -1;
            top: -150px;
            height: 500px;
        }
    }
}
/* Medium devices (desktops, 992px and up) */
@media (min-width: 992px) {
    .slider-top-content {
        position: relative;
        top: -200px;
    }
    .slider-controls {}
    .carousel-static__content {
        .carousel-static__content--image {
            top: -250px;
        }
    }
}
/* Large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
    .slider-controls {
        left: -60px;
    }
}
//Reservation Page
.page-reservation {
    padding: 25px auto;
    color: white;
    background-color: @page-color;
    background-image: url("../../assets/img/bg/pattern.png");
}
.reservation {
    margin: 0 auto;
}
//form
.form-back {
    position: relative;
    top: -50px;
    left: -118px;
    .back {
        height: 22px;
        padding: 5px 10px 5px 0;
    }
}
.form-container {
    display: block;
    max-width: 700px;
    margin: 60px auto;
    padding: 20px 40px;
    background-color: @page-color;
    p {
        font-size: 16px;
        font-weight: bold;
    }
    small {
        font-size: 12px;
        font-weight: normal;
    }
}
.form-group {
    margin-bottom: 30px;
}
.form {}
.success {
    color: lightgreen;
}
.contact-form {}
.submit-form {
    display: block;
    width: 100%;
    margin: 20px auto;
}
.field {
    display: inline-block;
    width: 100%;
    input,
    label {
        //display:block;
    }
}
label {
    color: #fff;
    font-size: 16px;
    font-weight: normal;
}
input,
select {
    color: #333;
    font-size: 18px;
}
select::after {
    position: relative;
    top: 0;
    width: 50px;
    height: 50px;
    background: url('../assets/img/icons/arrow-left.png');
}
input {}
label {
    vertical-align: top;
}
.form-control {}
.make-reservation {
    width: 100%;
    margin-bottom: 20px;
    padding: 10px 0;
    background-color: #b29130;
    font-weight: bold;
}
.antispam {
    display: none;
}
@media (max-width: 767px) {
    .form-back {
        left: -35px;
    }
}
/* Small devices (tablets, 768px and up) */
@media (min-width: 768px) {
    .field {
        width: 49.6%;
    }
    .form-container {
        padding: 20px 120px;
    }
}
/* Medium devices (desktops, 992px and up) */
@media (min-width: 992px) {}
/* Large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {}
