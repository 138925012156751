@nice-blue: #5B83AD;
@light-grey: rgba(240,240,240,1);

.center{
    text-align:center;
    display:block !important;
    margin:0 auto;
}

.box-shadow{
  box-shadow:         0px 3px 3px 0px rgba(50, 50, 50, 0.75);
}

.bold{
    font-weight:bold !important;
}
